import axios from "axios";
import { useAuth } from "../context/AuthProvider";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { BACKEND_URL } from "../utils/constant";

const DeleteConfirmPopup = ({ id, reload, entity }) => {
    const { setPopup } = useAuth();
    const formatMessage = useFormatMessage();
    const onConfirmClick = (e) => {
        e.preventDefault();
        const remark = document.getElementById('delete-remark-input')
        axios
            .delete(BACKEND_URL + `/${entity}/` + id, { data: {remark: remark.value},withCredentials: true })
            .then((response) => {
                if (response.status === 200) {
                    setPopup(null);
                    reload();
                }
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    const onCloseClick = (e) => {
        e.preventDefault();
        setPopup(null);
    };

    return (
        <form onSubmit={onConfirmClick} className="popup">
            <h5 className="delete-confirm-title">
                အချက်အလက်အား ဖျက်သိမ်းရန် အတည်ပြုပါ။
            </h5>
            <div className="grow mb-7">
                <label className="x-label input-required">
                မှတ်ချက်
                </label>
                <textarea
                    rows={5}
                    cols={40}
                    required
                    type="textarea"
                    name="remark"
                    id="delete-remark-input"
                    // onChange={handleInputChange}
                    placeholder="မှတ်ချက် ရိုက်ထည့်ပါ"
                    className="border-2 px-4 py-2 rounded-md border-gray-300"
                />
            </div>

            <div className="flex gap-5 justify-end">
                <button onClick={onCloseClick} className="white-btn">
                    ပယ်ဖျက်မည်
                </button>
                <button type="submit" className="secondary-btn">
                    ဖျက်မည်
                </button>
            </div>
        </form>
    );
};

export default DeleteConfirmPopup;

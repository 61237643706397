import { useEffect, useState } from "react"


export const useSwitchLang = () => {
    const [lang, setLang] = useState('en');

    const switchLang = (lang) => {
        localStorage.setItem('locale', lang)
        if (lang === 'eng')
        {
            setLang('en')
        }
        else if (lang === 'myan')
        {
            setLang('my')
        }
    }

    useEffect(() => {
        switchLang(localStorage.getItem('locale', lang))
    }, [])
    

    return {
        lang, switchLang
    }
}
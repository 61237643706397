import React from "react";
import "../css/dashboardTemplate.css";
import logo from "../img/app_logo.png";
import logoutIcon from "../img/icon-logout.png"
import barsIcon from "../img/bars.png";
import backIcon from "../img/back_arrow.png"; 
import downArrow from "../img/profile_drown_arrow.png";
import profilePic from "../img/user_profile.png";
import Navigation from "./Navigation";
import { useAuth } from "../context/AuthProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function DashboardTemplate({ children }) {
    const {logout, backUrl} = useAuth()
    const history = useHistory()

    const onBackClick = () => {
        history.push(backUrl)
    }

    return (
        <div className="page">
            <div className="dashboard-layout">
                <div className="side-nav">
                    <div className="dashboard-logo">
                        <img className="dashboard-button" src={barsIcon} />
                        <img className="app-icon" src={logo} />
                    </div>
                    <Navigation />
                </div>
                <div className="sub-layout">
                    <div className="topbar">
                        <img
                        onClick={onBackClick}
                            className="back-icon cursor-pointer"
                            src={backIcon}
                        />

                        <div className="user-profile profile-layout">
                            <div className="block">
                                <span className="block username">
                                    အောင်အောင်
                                </span>
                                <span className="block">အောင်အောင်</span>
                                <span className="block">အောင်အောင်</span>
                            </div>
                            <img className="profile-pic" src={profilePic} />
                            <img
                                className="down-icon cursor-pointer"
                                src={downArrow}
                            />

                            <div className="profile-popup">
                                
                                <div onClick={() => logout()}>
                                    <img src={logoutIcon} />
                                    <p>Logout</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    {children}
                </div>
            </div>
        </div>
    );
}

export default DashboardTemplate;

// import fastPrevIcon from '../img/icon-fast-prev.png'
// import prevIcon from '../img/icon-prev.png'
// import fastnextIcon from '../img/icon-fast-next.png'
// import nextIcon from '../img/icon-next.png'

export const Pagination = ({
    data: { page, totalCount, limit },
    setCurrentPage,
}) => {
    const totalPages = Math.ceil(totalCount / limit);
    const paginationArray = [1];
	const start = ((page - 1) * limit) + 1;
	const end = page * limit > totalCount ? totalCount :  page * limit

    if (page > 2) {
        paginationArray.push("...");
    }

    for (
        let i = Math.max(2, page - 1);
        i <= Math.min(totalPages - 1, page + 1);
        i++
    ) {
        paginationArray.push(i);
    }

    if (page < totalPages - 2) {
        paginationArray.push("...");
    }

    if (totalPages > 1) {
        paginationArray.push(totalPages);
    }

    const onPageClick = (item) => {
        return () => {
            if (typeof item === "number") {
                console.log(item);
                setCurrentPage(item);
            }
        };
    };

    return (
        <div className="pagination-container">
			<p className="block">Showing {start} to {end} of {totalCount} entries</p>
            <ul className="pagination">
                <li onClick={() => setCurrentPage(page - 1)}>Previous</li>
                {paginationArray.map((item, index) => (
                    <li
                        onClick={onPageClick(item)}
                        key={index}
                        className={item == page ? "active" : ""}
                    >
                        {item}
                    </li>
                ))}
                <li onClick={() => setCurrentPage(page + 1)}>Next</li>
            </ul>
        </div>
    );
};

import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-numberformat/polyfill-force";
import "@formatjs/intl-numberformat/locale-data/my";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-datetimeformat/polyfill-force";
import "@formatjs/intl-datetimeformat/locale-data/my";
import "@formatjs/intl-datetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/polyfill-force";
import "@formatjs/intl-relativetimeformat/locale-data/my";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAuth } from "./context/AuthProvider";
import PrivateRoute from "./context/PrivateRoute";
import PublicRoute from "./context/PublicRoute";
import Login from "./pages/Login";
import messages from "./utils/messages.json";
import flatten from "flat";
import NotFound from "./pages/NotFound";
import PopupRender from "./components/PopupRender";
import Coupon from "./pages/Coupon/Coupon";
import CreateCoupon from "./pages/Coupon/CreateCoupon";
import PrintCoupon from "./pages/Coupon/PrintCoupon";

function App() {
    const { user, checkUser, lang, app } = useAuth();

    return (
        <IntlProvider
            locale={lang}
            messages={flatten(messages[lang])}
            defaultLocale="en"
        >
            <Router>
                <Switch>
                    <PublicRoute exact path="/" component={Login} />

                    <Route path="/dashboard">
                        <PrivateRoute
                            exact
                            path="/dashboard/coupon"
                            component={Coupon}
                            module=""
                        />
                        <PrivateRoute
                            exact
                            path="/dashboard/coupon/create"
                            component={CreateCoupon}
                            module=""
                        />

                        <PrivateRoute
                            exact
                            path="/dashboard/coupon/:id/print"
                            component={PrintCoupon}
                            module=""
                        />
                        {/* <PrivateRoute
                            exact
                            path="/dashboard/supplier/:id/edit"
                            component={EditSupplier}
                            module="Supplier Management"
                        /> */}
                    </Route>

                    <Route>
                        <div className="w-screen h-screen">
                            <NotFound />
                        </div>
                    </Route>
                </Switch>
            </Router>

            {app.popup && <PopupRender popup={app.popup} />}
        </IntlProvider>
    );
}

export default App;

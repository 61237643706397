import React, { useRef, useState } from 'react'
import app_logo from '../img/app_logo.png'
// import sponsor_logo from '../img/edka-sponsor.png'
import '../css/login.css'
import { FormattedMessage } from 'react-intl'
import { useAuth } from '../context/AuthProvider';
import axios from 'axios';
import { BACKEND_URL } from '../utils/constant';
import { useForm } from '../hooks/useForm';

function Login() {
	const {switchLang, lang, setUser, checkUser, setLoading} = useAuth() ;
	const {handleInputChange, formData, error, setError, submitForm} = useForm();

	const onChangeLang = (e) => {
		switchLang(e.target.id)
	}

	const onsubmit = (e) => {
		e.preventDefault()
		const options = {
			method: 'POST',
			endpoint: '/auth/login'
		}
		setLoading(true)
		submitForm('POST', '/auth/login', formData, response => {
			checkUser()

		})
	}

	return (
		
		<section className='page'>
			<div className='login-section'>
				<div className='container'>

					<div className='left-side'>
						<img className='logo'
						 src={app_logo}
						  />
					</div>

					<div className='divider'></div>

					<form className='right-side' onSubmit={onsubmit}>
						<h1>
							အကောင့်၀င်ရန်
						</h1>
						<div className='input-item'>
							<label className='input-required' htmlFor='username'>
								အသုံးပြုသူ အမည်
							</label>
							<input 
							required
							id='username' 
							type='text' 
							name='username'
							placeholder='အသုံးပြုသူ အမည် ရိုက်ထည့်ပါ'
							onChange={handleInputChange}/>
						</div>
						<div className='input-item' style={{marginTop: '20px'}}>
							<label htmlFor='password'  className='input-required'>
								စကား၀ှက်
							</label>
							<input 
							required
							id='password' 
							type='password' 
							name='password'
							placeholder='စကား၀ှက် ရိုက်ထည့်ပါ'
							onChange={handleInputChange}/>
						</div>

						<div className='w-full'>
							<p className='text-red-400 p-0 mt-2'>{error && 'Incorrect username or password!'}</p>
						</div>
						<div className='button-container'>
							<button type='submit' className='theme-btn' >
								အကောင့်၀င်ရန်
							</button>
						</div>

					</form>

				</div>



			</div>

		</section>
	)
}

export default Login
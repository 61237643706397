/* eslint-disable jsx-a11y/alt-text */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
// import backArrowIcon from "../../img/back-arrow.png";
import DataDropdown from "../../components/DataDropdown";
import CreateCompany from "../../components/CreateCompnay";
import searchIcon from "../../img/search-icon.png";
import closeIcon from "../../img/close.png";
import axios from "axios";
import { BACKEND_URL } from "../../utils/constant";
import SearchCompanyPopup from "../../components/SearchCompanyPopup";

export default function CreateCoupon() {
    const { handleInputChange, formData, setFormData, submitForm, error } = useForm();
    const formatMessage = useFormatMessage();
    const { setPopup } = useAuth();
    const history = useHistory();
    const { user, setLoading, setBackUrl } = useAuth();
    const [company_name_empty, setError] = useState(false);
    const [toggleSearch, setToggler] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
        };

        if(data.b2b_customer_id == null || data.b2b_customer_id == ""){
            setError(true)
            return null;
        }

        delete data.company_name
        submitForm("POST", "/coupon", data, (response) => {
            history.push(`/dashboard/coupon/${response.data.data.coupon_id}/print`);
        });
    };

    const setCompany = (b2b_customer_id, name) => {
        setFormData({
            ...formData,
            b2b_customer_id,
            company_name: name
        })
        setToggler(true);
    }

    const onSearch = e => {
        if(formData.company_name == undefined){
            formData.company_name = "";
        }
        axios.get(BACKEND_URL+ '/b2bCustomer?company_name='+ formData.company_name, {withCredentials: true})
        .then(response => {
           setError(false);
           setPopup(<SearchCompanyPopup setCompany={setCompany} data={response.data.data}/>)
        })
        .catch(error => {
        });
        

    }

    const onRemoveCompany = e => {
        setFormData({
            ...formData,
            b2b_customer_id : "",
            company_name: ""
        })
        setToggler(false);
    }

    useEffect(() => {
        setBackUrl('/dashboard/coupon')
    }, [])

    return (
        <div className="sub-page-layout-1">
            {/* <Link className="back-to-page" to="/dashboard/supplier">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link> */}
            <div className="container">
                <h1 className="sub-page-title">ကူပွန် အသစ်ဖန်တီးခြင်း</h1>
                <h2 className="text-2xl mt-8 mb-6">ကူပွန် အချက်အလက်</h2>
                <div className="form-container">
                    <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div className="" style={{ maxWidth: "540px" }}>
                            <div className="grow mb-7 relative">
                                <div className="flex w-full justify-between">
                                    <label className="x-label input-required">
                                        ကုမ္ပဏီအမည်
                                    </label>
                                    <span
                                        onClick={() =>
                                            setPopup(<CreateCompany />)
                                        }
                                        className="theme-text cursor-pointer"
                                    >
                                        + အသစ်ထည့်ရန်
                                    </span>
                                </div>
                                {company_name_empty && <span className="error" style={{color: "red"}}>Please add valid company</span>}
                                <input
                                    required
                                    type="text"
                                    disabled={formData.b2b_customer_id}
                                    value={formData.company_name || ''}
                                    name="company_name"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />

                                {toggleSearch && <img
                                onClick={onRemoveCompany}
                                src={closeIcon} style={{
                                    width: '20px',
                                    height: 'auto',
                                    position: 'absolute',
                                    bottom: '13px',
                                    right: '15px',
                                    cursor: 'pointer'
                                }} />}

                                {!toggleSearch && <img
                                onClick={onSearch}
                                src={searchIcon} style={{
                                    width: '30px',
                                    height: 'auto',
                                    position: 'absolute',
                                    bottom: '9px',
                                    right: '15px',
                                    cursor: 'pointer'
                                }} />}
                            </div>
                            <div className="grow mb-7">
                                <label className="x-label input-required">
                                    ငွေပမာဏ
                                </label>
                                <input
                                    required
                                    type="number"
                                    name="coupon_amount"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow mb-7">
                                <label className="x-label input-required">
                                    ကူပွန်ထုတ်ပေးသည့်ရက်
                                </label>
                                <input 
                                required
                                    type="date"
                                    name="coupon_date"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow mb-7">
                                <label className="x-label input-required">
                                    ကူပွန်သက်တမ်းကုန်မည့်ရက်
                                </label>
                                <input 
                                required
                                    type="date"
                                    name="expiry_date"
                                    onChange={handleInputChange}
                                    className="x-input  theme-calendar"
                                />
                            </div>
                            <div>
                                {error ? (
                                    <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                        {error}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <div className="flex justify-end gap-5">
                                    <Link
                                        to={`/dashboard/coupon`}
                                        className="white-btn"
                                    >
                                        ပယ်ဖျက်ရန်
                                    </Link>
                                    <button className="theme-btn">
                                        ဖန်တီးရန်
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

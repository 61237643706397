import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { useForm } from "../hooks/useForm";
import { useFormatMessage } from "../hooks/useFormatMessage";
import DataDropdown from "./DataDropdown";

export default function CreateCompany() {
    const { handleInputChange, formData, submitForm, error } = useForm();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading, setPopup } = useAuth();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        console.log(data)
        submitForm("POST", "/b2bCustomer", data, () => {
            setPopup(null)
        });
    };

    return (
        <div className="popup-form-container">
            <form
                autoComplete="off"
                onSubmit={onSubmit}
                className="form flex flex-col justify-between w-full"
            >
                <h4 className="title">အသစ်ထည့်ရန်</h4>
                <p className="text-2xl mb-6">ကူပွန် အချက်အလက်</p>
                <div className="grid grid-cols-2 gap-x-16 w-full justify-between">
                    <div className="grow mb-7">
                        <label className="x-label input-required">
                            ကုမ္ပဏီအမည်
                        </label>
                        <input
                            required
                            type="text"
                            name="company_name"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label input-required">
                            ကုမ္ပဏီ ဖုန်းနံပါတ် ၁
                        </label>
                        <input
                            required
                            type="number"
                            name="company_phone1"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            ကုမ္ပဏီ ဖုန်းနံပါတ်၂
                        </label>
                        <input
                            // required
                            type="number"
                            name="company_phone2"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            ကုမ္ပဏီ လိပ်စာ
                        </label>
                        <input
                            type="text"
                            name="company_address"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            Postal Code
                        </label>
                        <input
                            type="number"
                            name="postal_code"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            ကုမ္ပဏီ Website
                        </label>
                        <input
                            type="text"
                            name="company_website"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            ကုမ္ပဏီ Email
                        </label>
                        <input
                            type="text"
                            name="company_email"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                </div>

                <p className="text-2xl mb-6">ဆက်သွယ်ရန် အချက်အလက်</p>
                <div className="grid grid-cols-2 gap-x-16 w-full justify-between">
                    <div className="grow mb-7">
                        <label className="x-label">
                            ဆက်သွယ်သူအမည်
                        </label>
                        <input
                            type="text"
                            name="contact_person"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            ဆက်သွယ်ရန် ဖုန်းနံပါတ်၁
                        </label>
                        <input
                            type="number"
                            name="contact_phone1"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            ဆက်သွယ်ရန် ဖုန်းနံပါတ်၂
                        </label>
                        <input
                            type="number"
                            name="contact_phone2"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                    <div className="grow mb-7">
                        <label className="x-label">
                            ဆက်သွယ်ရန် Email
                        </label>
                        <input
                            type="text"
                            name="contact_email"
                            onChange={handleInputChange}
                            className="x-input"
                        />
                    </div>
                </div>

                <div>
                    {error ? (
                        <span className="w-full block text-lg mb-3 text-red-500 text-right">
                            {error}
                        </span>
                    ) : (
                        ""
                    )}
                    <div className="flex justify-end gap-5">
                        <a onClick={() => setPopup(null)} className="white-btn">
                            ပယ်ဖျက်ရန်
                        </a>
                        <button className="theme-btn">ဖန်တီးရန်</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

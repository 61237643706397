import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const { useEffect, useState } = require("react");

const useGetList = ({ entity, extQuery = '', setData = () => {} }) => {
    const [list, setlist] = useState([])

    useEffect(() => {
        const url = BACKEND_URL + `/${ entity }?status=ACTIVE` + `${extQuery}`;
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                setlist(response.data.data);
                setData(response.data.data)
            })
            .catch((e) => {
                // console.log(e);
            });
    }, [extQuery]);

    return list;

};

export default useGetList

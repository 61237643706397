import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { allow } from "../utils/common";
import { useAuth } from "../context/AuthProvider";
import couponIcon from '../img/coupon.png'

function Navigation() {
    const formatMessage = useFormatMessage();

    return (
        <div className="navigation">
            <NavItem
                icon={couponIcon}
                to="/dashboard/coupon"
                text={"ကူပွန်"}
                // module="Supplier Management"
            />
        </div>
    );
}

const NavItem = ({ icon, text, to, module }) => {
    const history = useHistory();
    const { user } = useAuth();
    const currentPath = history.location.pathname;
    const active = currentPath.startsWith(to);

    if (module !== "dashboard" && !allow(user.userAccess, module)) {
        return <></>;
    }

    return (
        <Link to={to} className={`nav-item ${active ? "active" : ""}`}>
            <img 
            src={icon} 
            />
            <span className="block h-6">{text}</span>
        </Link>
    );
};

const NestedNavItem = ({ icon, to, text, subnav }) => {
    const { user } = useAuth();
    const history = useHistory();
    const currentPath = history.location.pathname;
    let isActive = false;
    subnav.forEach((item) => {
        if (currentPath.startsWith(item.link)) {
            isActive = true;
        }
    });

    subnav = subnav.filter((item) => allow(user.userAccess, item.module));
    if (subnav.length === 0) {
        return <></>;
    }

    return (
        <div className={`nested-nav-item`}>
            <Link to={to} className={`parent-nav ${isActive ? "active" : ""}`}>
                <img 
                // src={icon} 
                />
                <span>{text}</span>
                <img 
                // src={downArrow} 
                className="downarrow" />
            </Link>
            {isActive && (
                <div className="child-nav">
                    {subnav.map((item, index) => {
                        return (
                            <Link
                                key={index}
                                className={`sub-nav-item ${
                                    currentPath.startsWith(item.link)
                                        ? "active"
                                        : ""
                                }`}
                                to={item.link}
                            >
                                {item.text}
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Navigation;

import React from 'react'

function NotFound() {
  return (
    <div className='w-full h-full text-xl text-gray-400 flex justify-center items-center'>
        404 | Not Found
    </div>
  )
}

export default NotFound
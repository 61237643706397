/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import searchIcon from "../../img/search_icon.png";
import "../../css/common.css";
import dotsIcon from "../../img/dots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL, GeneralStatus } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
// import editIcon from "../../img/icon-edit.png";
// import trashCanIcon from "../../img/icon-trash.png";
// import detailIcon from "../../img/icon-details.png";
// import crossIcon from "../../img/icon-cross.png";
// import reloadIcon from "../../img/icon-reload.png";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { useAuth } from "../../context/AuthProvider";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";
import moment from "moment";

export default function Coupon() {
    const history = useHistory();
    const { setPopup } = useAuth();
    const currentPath = history.location.pathname;
    const [isOptionOpen, setisOptionOpen] = useState(false);
    const { data, setCurrentPage, setQuery, abortController, reload } = useFetchData({
        url: BACKEND_URL + "/coupon",
        defaultQuery: "",
    });
    const formatMessage = useFormatMessage();

    const toggleOptions = (id) => {
        const optionEl = document.getElementById(id);
        const activeOptionEl = document.querySelectorAll(".popup-options.open");
        console.log(activeOptionEl);
        if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
            optionEl.classList.toggle("open");
            setisOptionOpen(optionEl.classList.contains("open"));
        }
    };

    const onSearchClick = (e) => {
        const text = document.getElementById('coupon-search').value
        setQuery({
            searchValue: text.trim(),
        });
    };

    const onDeleteClick = (e) => {
        e.preventDefault();
        const id = e.target.parentNode.id
            ? e.target.parentNode.id
            : e.target.parentNode.parentNode.id;
        setPopup(
            <DeleteConfirmPopup id={id} reload={reload} entity="coupon" />
        );
    };

    useEffect(() => {
        const openedEl = document.querySelector(".popup-options.open");

        const handleDocumentClick = (e) => {
            if (e.target != openedEl && !openedEl.contains(e.target)) {
            }
            openedEl.classList.remove("open");
            setisOptionOpen(false);
        };
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener("click", handleDocumentClick);
            }, 100);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOptionOpen]);

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>ကူပွန်စာရင်း</h1>
                <div className="search-bar">
                    <div className="search-bar-container">
                        <img src={searchIcon} onClick={onSearchClick}/>
                    </div>
                    <input
                    id="coupon-search"
                        className="x-input"
                        type="search"
                        placeholder="ကူပွန်ကုဒ်, ကုမ္ပဏီအမည် ဖြင့်ရှာရန်"
                    />
                </div>
                <Link to="/dashboard/coupon/create" className="create-button">
                    + အသစ်ထည့်ရန်
                </Link>
            </div>

            <div className="table-container">
                <table className="x-table">
                    <thead>
                        <tr>
                            <th>ကူပွန်ကုဒ်</th>
                            <th>ကုမ္ပဏီအမည်</th>
                            <th>ငွေပမာဏ</th>
                            <th>ကူပွန်ထုတ်ပေးသည့်ရက်</th>
                            <th>ကူပွန်သက်တမ်းကုန်မည့်ရက်</th>
                            <th className="button-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.results.map((item, i) => {
                            const index = (data.page - 1) * data.limit + i + 1;
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{item.coupon_code}</td>
                                    <td>{item.customer.company_name}</td>
                                    <td>{item.coupon_amount}</td>
                                    <td>
                                        {moment(item.coupon_date).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td>
                                        {moment(item.expiry_date).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                    <td className="button-col">
                                        <div className="flex items-center">

                                            <img
                                                className="col-button"
                                                src={dotsIcon}
                                                onClick={() =>
                                                    toggleOptions(
                                                        item.coupon_id
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            className="popup-options"
                                            id={item.coupon_id}
                                        >
                                            <a href={"/dashboard/coupon/" + item.coupon_id + "/print"}>ပရင့်ထုတ်ရန်</a>
                                            <a href="" onClick={onDeleteClick}>
                                                ဖျက်ရန်
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <Pagination data={data} setCurrentPage={setCurrentPage} />
        </div>
    );
}

import axios from "axios";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { APP_URL, BACKEND_URL } from "../../utils/constant";
import { couponTemplate } from "../../img/coupon_template.jpeg";
import moment from "moment";
import { useAuth } from "../../context/AuthProvider";

export default function PrintCoupon() {
    const param = useParams();
    const {setBackUrl} = useAuth()
    const [data, setdata] = useState();

    useEffect(() => {
        setBackUrl('/dashboard/coupon')
        axios
            .get(BACKEND_URL + "/coupon?coupon_id=" + param.id, {
                withCredentials: true,
            })
            .then((response) => {
                console.log(response.data.data);
                setdata(response.data.data[0]);
            })
            .catch((error) => {});
    }, []);

    const printCoupon = () => {
        var a = window.open("", "", "height=full, width=full");
        const couponCss = `
        body {margin: 0}
        .coupon-template {
            width: 860px;
            height: 384px;
            background-image: url('${APP_URL}/coupon_template.jpeg');
            background-size: contain;
            position: relative;
        }
   
        .coupon-date {
            position: absolute;
            bottom: 12.7%;
            right: 8.6%;
            letter-spacing: .9px;
            line-height: 24px;
        }

        .coupon-code {

            position: absolute;
            top: 10.7%;
            right: 8.6%;
            letter-spacing: .3px;
            line-height: 28px;
        }
        `;
        const divContents =
            document.querySelector("#coupon-template").innerHTML;
        a.document.write("<html><body>");
        a.document.write(`<style>${couponCss}</style>`);

        a.document.write(divContents);
        a.document.write("</body></html>");
        setTimeout(() => {
            a.print();

            a.close()
        }, 500)
    };

    return (
        <div className="sub-page-layout-1">
            {data && (
                <>
                    <div id="coupon-template">
                        <div className="coupon-template">
                            <span className="coupon-code">
                                Code No . {data.coupon_code}
                            </span>
                            <span className="coupon-date">
                                Valid Till -{" "}
                                {moment(data.expiry_date).format(
                                    "DD, MMMM, YYYY"
                                )}
                            </span>
                            <QRCode
                                size={256}
                                style={{
                                    height: "auto",
                                    maxWidth: "62px",
                                    width: "100px",
                                    position: "absolute",
                                    bottom: "6%",
                                    left: "3%",
                                }}
                                value={data.coupon_id}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                    <button onClick={printCoupon} className="theme-btn mt-6">
                        ပရင့်ထုတ်မည်
                    </button>
                </>
            )}
        </div>
    );
}

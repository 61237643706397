import axios from "axios"
import { useEffect, useState } from "react"


export const useFetchData = ({url, defaultQuery, sort = '-created_time'}) => {
    const [data, setdata] = useState({
        results: [],
        page: 1,
        limit: 10,
        totalCount: 0,
        query: {},
        sort: sort,
        reloadTrigger: false,
    })
    // const [abortController, setabortController] = useState(new AbortController())
   
    
    const setCurrentPage = (page) => {
        const totalPage = Math.ceil(data.totalCount/10);
        page = page < 1 ? 1 : page;
        page = page > totalPage ? totalPage : page;

        setdata({
            ...data,
            page
        })
    }
    let abortController
    useEffect(() => {
        let query = [];
        abortController = new AbortController()
        query.push(defaultQuery)
        for (const key in data.query) {
            query.push(`${key}=${data.query[key]}`)
        }
        
        query = query.join('&');
        axios.get(`${url}?page=${data.page}&limit=${data.limit}&sort=${sort}&included=true&${query}`, {
            signal: abortController.signal,
            withCredentials: true
        })
        .then(response => {
            setdata({
                ...data,
                results: response.data.data,
                totalCount: response.data.totalCount
            })
        })
        .catch(e => {
            // console.log(e)
        })
        return () => {
            abortController.abort()
        }
    }, [data.page, data.limit, data.query, data.reloadTrigger])
    
    const setQuery= (query) => {
        for (const key in query) {
            if (!query[key])
            {
                delete query[key]
            }
        }

        setdata({
            ...data,
            page: 1,
            query
        })
    }

    const reload = () => {
        setdata({
            ...data,
            reloadTrigger: !data.reloadTrigger
        })
    }

    return {
        data,
        abortController,
        setCurrentPage,
        setQuery,
        reload
    }
}




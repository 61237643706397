import moment from "moment";
import closeIcon from '../img/close.png'
import { useAuth } from "../context/AuthProvider";

export default function SearchCompanyPopup({data, setCompany}) {
    const {setPopup} = useAuth()

    const selectCompany = (id, name) =>  {
        return e => {
            setCompany(id, name)
            setPopup(null)
        }
    }

    return (
        <div className="bg-white px-12 py-8 rounded w-full relative" style={{maxWidth: '1080px'}}>
            <h4 className="text-2xl">ကုမ္ပဏီ အချက်အလက်</h4>
            <img 
            onClick={() => setPopup(null)}
            src={closeIcon} style={{
                width: '16px',
                height: 'auto',
                top: '16px',
                right: '16px',
                position: 'absolute',
                cursor: 'pointer'
            }}/>
            <div className="table-container">
                <table className="x-table">
                    <thead>
                        <tr>
                            <th>ကုမ္ပဏီအမည်</th>
                            <th>ဆက်သွယ်သူအမည်</th>
                            <th>ဖုန်းနံပါတ် ၁</th>
                            <th>လိပ်စာ</th>
                            <th className="button-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, i) => {
                          
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{item.company_name}</td>
                                    <td>{item.contact_person}</td>
                                    <td>{item.contact_phone1}</td>
                                    <td>{item.company_address}</td>
                                    <td className="button-col">
                                        <div 
                                        onClick={selectCompany(item.b2b_customer_id, item.company_name)}
                                        className="bg-green-500 cursor-pointer px-2 py-1 rounded text-white">
                                        ထည့်ရန်
                                        </div>
                                      
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

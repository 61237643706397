import React from 'react'

function PopupRender({popup}) {
  return (
    <div id='popup-render' className='popup-render'>
        {popup}
    </div>
  )
}

export default PopupRender
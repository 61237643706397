import { useIntl } from "react-intl"

export const useFormatMessage = () => {
    const intl = useIntl();


    return (id, text) => {
        return intl.formatMessage({id: id}).replace('{{var}}', text)
    }

}
